import axios from "axios";
import piecesJson from "../data/piece.json"
import partecipantsJson from "../data/participant.json"
import exhibition from "../data/exhibition.json"

const API_URL = process.env.REACT_APP_API_URL;

export interface Piece {
    id: number,
    slug: string,
    input_original: string,
    input_translated: string,
    created_at: Date,
    updated_at: Date,
    account_username: string,
    tweet_retweet_count: number,
    tweet_reply_count: number,
    tweet_like_count: number,
    artifact_url_1: string,
    artifact_url_2: string,
    artifact_url_3: string,
    artifact_url_4: string,
    tweeted_at: string,
    tweet_id: string,
    tweet_response_id: string,
    participant?: Participant,
}

export interface Participant {
    username: string,
    profile_url: string,
    affiliated_party: string,
    created_at: Date,
    updated_at: Date,
    twitter_id: string,
    pieces: Piece[],
}

export const fetchExhibition = () => {
    return new Promise((resolve, reject) => {
        try {
            resolve({"data": exhibition});
        } catch (err: any) {
            reject(err);
        }
    });
    // @ts-ignore
    //return axios.get(API_URL + '/api/exhibition');
}

export const fetchPiece = (tweet_id: string) => {
    return new Promise((resolve, reject) => {
        try {
            // @ts-ignore
            const piece: Piece = piecesJson.find((p: Piece) => p.tweet_id === tweet_id);
            resolve({"data": piece});
        } catch (err: any) {
            reject(err);
        }
    });
    // @ts-ignore
    // return axios.get(API_URL + '/api/exhibition/piece/' + tweet_id);
}

export const fetchParticipant = (username: string) => {
    return new Promise((resolve, reject) => {
        try {
            // @ts-ignore
            const participant: Participant = partecipantsJson.find((p: Participant) => p.username === username);
            // @ts-ignore
            const pieces: Piece[] = piecesJson.filter((p: Piece) => p.account_username === username);

            participant["pieces"] = pieces;
            resolve({"data": participant});
        } catch (err: any) {
            reject(err);
        }
    });
    // @ts-ignore
    // return axios.get(API_URL + '/api/exhibition/participant/' + username);
}